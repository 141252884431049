import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { Suspense, lazy } from "react";
import Loader from "./components/Loader";
import Fallback from "./components/Fallback";

const Routing = () => {
  const PropertyDetail = lazy(
    () => import("./pages/Individual/PropertyDetail/index")
  );
  const LandingPageWrapper = lazy(
    () => import("./ProtectedRoutes/LandingPageWrapper")
  );
  const PostProperty = lazy(() => import("./pages/PostProperty/index"));
  const SeeAll = lazy(
    () => import("./pages/Individual/PropertyListing/SeeAll")
  );
  const ListedPropertyDetail = lazy(
    () => import("./pages/Individual/ListedPropertyDetail/index")
  );
  const Chat = lazy(() => import("./pages/Chat/index"));
  const WishList = lazy(() => import("./pages/Individual/Wishlist/index"));
  const Notifications = lazy(() => import("./pages/Notifications/index"));
  const IndividualProfile = lazy(
    () => import("./pages/Individual/Profile.tsx/index")
  );
  const PostAds = lazy(() => import("./pages/PostAds/index"));
  const PostAdsDetails = lazy(() => import("./pages/PostAdsDetail/index"));
  const AgentLandingPage = lazy(
    () => import("./pages/Agent/landingPage/index")
  );
  const AgentProfile = lazy(() => import("./pages/Agent/Profile/index"));
  const ComapnyProfile = lazy(() => import("./pages/Company/Profile/index"));
  const ContactUs = lazy(() => import("./pages/Cms/contactUs"));
  const AboutUs = lazy(() => import("./pages/Cms/aboutUs"));
  const Faq = lazy(() => import("./pages/Cms/faq"));
  const PrivacyPolicy = lazy(() => import("./pages/Cms/privacyPolicy"));
  const TermsAndCondition = lazy(() => import("./pages/Cms/termsConditions"));
  const PropertyListing = lazy(
    () => import("./pages/Individual/PropertyListing/index")
  );
  const Location = lazy(() => import("./pages/location/index"));

  const PerformanceInsights = lazy(() => import("./pages/insights/index"))


  const PropertyDetail2 = lazy(
    () => import("./pages/Individual/PropertyDetail2/index")
  );

  const FindAgent = lazy(
    () => import("./pages/FindAgent/index")
  );
  const SavedSearch = lazy(() => import("./pages/Individual/savedSearch/index"))

  const FindAgentDetail = lazy(
    () => import("./pages/FindAgent/detail")
  );
  const AssignAgent = lazy(
    () => import("./pages/Company/assignAgent")
  );
  const BlogListing = lazy(
    () => import("./pages/Blogs/listing")
  );
  const BlogDetail = lazy(
    () => import("./pages/Blogs/detail")
  );
  return (
    <>
      <Router>
        <Suspense fallback={<Fallback />}>
          <Routes>
            <Route path="/" element={<LandingPageWrapper />} />
            <Route path="/post-your-property" element={<PostProperty />} />
            <Route path="/post-your-property/:id" element={<PostProperty />} />
            <Route path="/property-listing" element={<PropertyListing />} />
            <Route path="/property-listing/see-all" element={<SeeAll />} />
            {/* <Route path="/property-detail/:id" element={<PropertyDetail2  />} /> */}
            <Route
              path="/listed-property-detail/:id"
              element={<ListedPropertyDetail />}
            />

            <Route path="/chat" element={<Chat />} />
            <Route path="/wishlist" element={<WishList />} />
            <Route path="/saved-search" element={<SavedSearch />} />
            <Route path="/notification" element={<Notifications />} />
            <Route path="/individual/profile" element={<IndividualProfile />} />
            <Route path="/individual/profile" element={<IndividualProfile />} />
            <Route path="/post-your-ads" element={<PostAds />} />
            <Route path="/ads-detail/:id" element={<PostAdsDetails />} />

            <Route path="/agent" element={<AgentLandingPage />} />
            <Route path="/agent/profile" element={<AgentProfile />} />

            <Route path="/company/profile" element={<ComapnyProfile />} />
            <Route path="/insights" element={<PerformanceInsights />} />

            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/location-map" element={<Location />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndCondition />}
            />
            <Route path="/property-detail/:id" element={<PropertyDetail2 />} />
            <Route path="/find-agent" element={<FindAgent />} />
            <Route path="/find-agent/detail/:id" element={<FindAgentDetail />} />
            <Route path="/assign-agent" element={<AssignAgent />} />
            <Route path="/blogs" element={<BlogListing />} />
            <Route path="/blogs/detail" element={<BlogDetail />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
};

export default Routing;

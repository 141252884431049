import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../app/store";

interface CurrencyRates {
    [currencyCode: string]: number; 
  }
  
  
  export interface CurrencySlice {
    currentCurrency: string; 
    rates: CurrencyRates; 
  }

const initialState: CurrencySlice = {
    currentCurrency:"AED",
    rates:{}

};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
   
    setCurrencyRates: (state, action: PayloadAction<CurrencyRates>) => {
      
        state.rates = { ...state.rates, ...action.payload };
    },
   
    resetCurrencyRates: (state) => {
      return initialState;
    },

    setCurrentCurrency: (state, action: PayloadAction<string>) => {
        state.currentCurrency = action.payload; 
      }
  },
});

export const { setCurrencyRates, setCurrentCurrency, resetCurrencyRates } = currencySlice.actions;
export const selectCurrencyRates = (state: RootState) => state.currencySlice.rates; 
export const selectCurrentCurrency = (state: RootState) => state.currencySlice.currentCurrency; 

export default currencySlice.reducer;


export const API_URL =
  "https://getpropmicroapi.appgrowthcompany.com/operational/api/v1/User/";

export const END_POINTS = {
  //Authentication
  signup: "signup",
  verifyOtp: "verifyOtp",
  sendOtp: "sendOtp",
  updateProfile: "updateProfile",
  upload: "Upload/uploadFiles",
  logout: "logout",
  socialLogin: "socialLogin",
  login: "login",
  deleteProfile: "deleteProfile",
  resetPassword: "resetPassword",
  getProfile: "getProfile",
  forgotPassword: "forgotPassword",
  changePassword: "changePassword",

  validateAccount: "validateAccount", //for otp send
  verifyAccount: "verifyAccount",

  propertyListing: "propertyListing",
  propertyFilter: "propertyFilter",

  //Token
  tokens: "tokens",
  topupByCompany: "topupByCompany",
  settings: "settings",
  category: "category",
  property: "property",
  agentRequest: "agentRequest",

  recommendedProperties: "recommendedProperties",
  dashboard: "dashboard",
  premiumListing: "premiumListing",

  wishlist: "wishlist",
  propertyDetailToken: "propertyDetailToken",
  getPropertyTransactionDetails: "getPropertyTransactionDetails",

  //ADS
  ads: "myAds",

  //agents
  agents: "agents",

  agencyDashboard: "agencyDashboard",

  cms: "cms",
  contactUs: "contactUs",
  boostPlans: "boostPlans",
  boostProfile: "boostProfile",
  subscriptionPlans: "subscriptionPlans",
  subscription: "subscription",
  amenities: "amenities",
  propertyType: "propertyType",
  propertyCondition: "propertyCondition ",
  //chat
  chatRequest: "chatRequest",
  updateUserRequest: "updateUserRequest",
  chats: "chats",
  notifications: "notifications",
  propertiesLocationWise: "propertiesLocationWise",
  adsBanner: "ads",

  //reels
  reels: "reels",

  // listing calculator
  propertyCalculator: "propertyCalculator",
  listing: "listing",
  chooseListingPlans: "chooseListingPlans",

  // insights
  leadsInsights: "leadsInsights",
  listingPerformanceDashboard: "listingPerformanceDashboard",
  leads: "leads",
  companyPerformance: "companyPerformance",
  agentInsights: "agentInsights",
  connectWithEmail: "connectWithEmail ",
  feedbackForm: "feedbackForm",
  findAgent: "findAgent",
  alert: "alert",
  searchHistory: "searchHistory",
  agentDetails: "agentDetails",
  leadsInsightDetails: "leadsInsightDetails",
  drafts:"drafts",
  requestedPropertiesByAgent:"requestedPropertiesByAgent"
};
